<template>
    <v-container
        id="be-instrum"
        tag="section"
    >
        <v-row
            justify="center"
            v-if="packageAroma.paytime >= dateNow"
        >        
            <v-col
                cols="12"
                lg="9"
                xl="7"
                class="block-media-lk"
            >
                <base-material-card
                    color="warning"
                    class="px-sm-8 px-md-16 px-2 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Soul of Nature
                        </div>

                        <div class="subtitle-1 font-weight-light">
                            Рефссылка, инструкции
                        </div>
                    </template>

                    <v-card-text
                        class="mt-10 mb-10 card-media-lk"
                    >
                    <p class="mb-7">
                      Soul of Nature - К сожалению, аромамасла сняты с производства.
                      </p>
                      <p class="mb-10">
                        Возможно, иногда будут выходить их ограниченные серии, и ты сможешь использовать данный сайт для продаж. Сейчас инструмент имеет лишь ознакомительный характер.
                      </p>
                        <v-expansion-panels
                            popout
                            focusable
                            class="exp-panel-title"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="green">mdi-numeric-1-box-multiple</v-icon>
                                            Твоя реферальная ссылка
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-right-bold-outline</v-icon>
                                        <router-link
                                            color="success"
                                            :to="{name: 'SoulOfNature', query: { partner: $store.state.user.profile.lr_number }}"
                                        >
                                            Лендинг Soul of Nature
                                        </router-link>
                                    </p>
                                    <p class="mb-n2">
                                        <v-icon class="mr-2" color="blue">mdi-arrow-down-bold-outline</v-icon>
                                        <strong>Твоя реферальная ссылка на лендинг Soul of Nature</strong>:
                                        <v-text-field
                                            :value="`https://newlvl.net/soul-of-nature/?partner=${$store.state.user.profile.lr_number}`"
                                            readonly
                                            id="referLinkAroma"
                                        ></v-text-field>
                                    </p>
                                    <v-btn
                                        color="gray"
                                        @click="copyRefLinkAroma"
                                        class="mb-5"
                                    >
                                        Скопировать ссылку
                                    </v-btn>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="red">mdi-numeric-2-box-multiple</v-icon>
                                            Рекомендации по работе с инструментом
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                      Как использовать данную страничку и масла для увеличения твоего заработка:
                                    </p>
                                    <p>
                                      <a href="https://teletype.in/@newlvl21/hKvdj6D6Z0p" target="_blank">Рекомендации</a>
                                    </p>
                                    <v-divider class="mb-5"></v-divider>
                                    
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="blue">mdi-numeric-3-box-multiple</v-icon>
                                            Инструкции от компании LR
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p class="mt-10">
                                      <a href="/files/aroma/LR_Soul_of_Nature__prezentacija_k_zapusku.pdf" target="_blank">Презентация к запуску (pdf)</a>
                                  </p>
                                  <p class="mt-5">
                                      <a href="/files/aroma/LR-702_HandBook_02-22_son.pdf" target="_blank">Справочник. Состав и рекомендации (pdf)</a>
                                  </p>
                                  <p class="mt-5">
                                      <a href="/files/aroma/LR-700_SoulOfNature_210x148_RU.pdf" target="_blank">Полное описание ассортимента (pdf)</a>
                                  </p>
                                  <p class="mt-5">
                                    <a href="/files/aroma/Аромадиффузор.pdf" target="_blank">Аромадиффузор (pdf)</a>
                                  </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="pink">mdi-numeric-4-box-multiple</v-icon>
                                            Секреты мастерства
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p class="mt-10">
                                      <img src="/img/aroma/instructions/secret-1.jpg" class="img-secret" />
                                      <img src="/img/aroma/instructions/secret-2.jpg" class="img-secret" />
                                    <img src="/img/aroma/instructions/secret-2.jpg" class="img-secret" />                                 
                                    <img src="/img/aroma/instructions/secret-3.jpg" class="img-secret" />                                  
                                    <img src="/img/aroma/instructions/secret-4.jpg" class="img-secret" />                                  
                                    <img src="/img/aroma/instructions/secret-5.jpg" class="img-secret" />                                  
                                    <img src="/img/aroma/instructions/secret-6.jpg" class="img-secret" />                                  
                                    <img src="/img/aroma/instructions/secret-7.jpg" class="img-secret" />                                  
                                    <img src="/img/aroma/instructions/secret-8.jpg" class="img-secret" />                                  
                                    <img src="/img/aroma/instructions/secret-9.jpg" class="img-secret" />                                  
                                    <img src="/img/aroma/instructions/secret-10.jpg" class="img-secret" />                                  
                                    <img src="/img/aroma/instructions/secret-11.jpg" class="img-secret" />
                                    <img src="/img/aroma/instructions/secret-12.jpg" class="img-secret" />
                                  </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="orange">mdi-numeric-5-box-multiple</v-icon>
                                            Блиц решения
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <p class="mt-10">
                                    <img src="/img/aroma/instructions/blic-1.jpg" class="img-blic" />
                                      <img src="/img/aroma/instructions/blic-2.jpg" class="img-blic" />
                                    <img src="/img/aroma/instructions/blic-2.jpg" class="img-blic" />                                 
                                    <img src="/img/aroma/instructions/blic-3.jpg" class="img-blic" /> 
                                  </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="purple">mdi-numeric-6-box-multiple</v-icon>
                                            Видеоматериалы
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                      <a href="https://youtu.be/lQKaM3u_LVk" target="_blank">Описание каждого масла</a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="https://youtu.be/cTUrnr_tVOY" target="_blank">Варианты сочетания масел</a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="https://youtu.be/heP6jTkURDA" target="_blank">Описание 4-х линеек</a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="https://youtu.be/Jc9PkkqRybE" target="_blank">Натуральные эфирные масла. Презентация от LR</a>
                                    </p>
                                    <v-divider class="mt-7" />
                                    <p class="mt-7">
                                      Решайте сами, как вы хотите повлиять на ваше внутреннее состояние с помощью природной силы эфирных масел.
                                      Вы можете наслаждаться ею каждый день, в любое время, в любом месте с помощью LR Soul of Nature.
Наш эксперт-ароматерапевт Юлия Крылова поделится с вами уникальными рецептами для создания определенной атмосферы: <br />
                                      <a
                                       href="https://drive.google.com/file/d/1y4KXH7SVcitZe05AskFpgL1SGVkYyEwB/view?usp=drivesdk"
                                        target="_blank">
                                        Уникальные рецепты для создания определенной атмосферы</a>
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="teal">mdi-numeric-7-box-multiple</v-icon>
                                            Ответы на вопросы
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                      <a href="https://youtu.be/V11KjzcUa6g" target="_blank">Ответы на вопросы</a>
                                    </p>
                                    <p class="mt-10">
                                      <a href="/files/aroma/LR_Soul_of_Nature_Voprosy_i_otvety.pdf" target="_blank">Вопросы и ответы (pdf)</a>
                                  </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                        <span>
                                            <v-icon class="mr-2" color="blue darken-3">mdi-numeric-8-box-multiple</v-icon>
                                            Масла по знакам зодиака
                                        </span>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="mt-10">
                                      <a href="https://teletype.in/@newlvl21/xBdaik2I0KT" target="_blank">Масла по знакам зодиака</a>
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>

                </base-material-card>
            </v-col>
        </v-row>
        <v-row
            justify="center"
            v-if="!packageAroma.paytime || packageAroma.paytime < dateNow"
        >
            <v-col
                cols="12"
                lg="9"
                xl="7"
            >
                <base-material-card
                    color="red"
                    class="px-md-16 px-5 py-3"
                >
                    <template v-slot:heading>
                        <div class="display-2 font-weight-light">
                            Ошибка доступа
                        </div>

                        <div class="subtitle-1 font-weight-light">

                        </div>
                    </template>

                    <v-card-text
                        class="mt-10"
                    >
                        <p>
                            <v-icon
                                color="red"
                                class="mr-2"
                            >
                                mdi-alert-circle-outline
                            </v-icon>
                            У вас нет доступа к просмотру данной страницы.</p>
                        <p>
                            <v-icon
                                class="mr-2"
                                color="blue"
                            >
                                mdi-arrow-right-bold-outline
                            </v-icon>
                            Для получения доступа к инструментам обратитесь к куратору:
                            <router-link
                                color="success"
                                :to="{name: 'PayInstruments'}"
                            >
                                Как получить доступ к инструментам
                            </router-link>
                        </p>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "AromaInstrum",
    data: () => ({
        dateNow: new Date().toISOString().substr(0, 10),
        dialog: true,
        paytimeUser: ''
    }),
    computed: {
        ...mapGetters({
            packageAroma: 'user/getProfileAroma'
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        }
    },
    created () {
        this.initializeUserPackage()
    },
    methods: {
        ...mapActions({
            loadPackageAroma: 'user/loadProfileAroma',
            loadProfilePackage: 'user/loadProfilePackage',
        }),
        copyRefLinkAroma () {
            let referLinkAroma = document.getElementById('referLinkAroma')

            referLinkAroma.select()

            document.execCommand('copy')
        },
        initializeUserPackage() {
            this.loadProfilePackage()
                .then((response) => {
                    this.loadingPackage = false
                    this.paytimeUser = response.data.paytime
                })
        },
    },
    mounted() {
        this.loadPackageAroma()
        this.loadProfilePackage()
    }
}
</script>

<style lang="sass">
.exp-panel-title .v-expansion-panel-header
    font-size: 14px

.exp-panel-title .v-expansion-panel--active
    border: 1px solid #69db69

.img-secret
  max-width: 300px
  margin-right: 10px

.img-blic
  max-width: 300px
  margin: 10px
  border: 1px solid #ccc
  border-radius: 10px

@media(max-width: 600px)
    .block-media-lk
        padding: 0

    .card-media-lk
        padding: 0

</style>
